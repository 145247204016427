<template>
  <div class="home">
    <a-layout style="height: 400px;">
      <a-layout-header>

        <div class="menu-demo">
          <a-menu mode="horizontal" :default-selected-keys="['1']">
            <a-menu-item key="0" :style="{ padding: 0, marginRight: '38px' }" disabled>
              <div :style="{
                width: '200px',
                height: '30px',
                borderRadius: '2px',
                color: 'black',
                fontSize: '20px',
                cursor: 'text',
              }">
                AlibabaCloud Server
              </div>
            </a-menu-item>
          </a-menu>
        </div>

      </a-layout-header>
      <a-layout-content>


        <div :style="{
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          padding: '40px',
          backgroundColor: 'var(--color-fill-2)',
        }">
          <a-card :style="{ width: '360px' }" title="Bt Panel" :bordered="false">
            <template #extra>
              <a-link href="https://aliyunserver.mooyun.cn:8888/login.jsp" target="_blank">登录控制面板</a-link>
            </template>

            <a-row class="grid-demo" v-for="item in datalist" :key="item">
              <a-col :span="6">
                <div>
                  <a-typography>
                    <a-typography-paragraph>
                      {{item.name}}
                    </a-typography-paragraph>
                  </a-typography>

                </div>
              </a-col>
              <a-col :span="18">
                <div>

                  <a-typography>
                    <a-typography-paragraph :copyable="item.copy" style="text-align: right;">
                      {{item.info}}
                    </a-typography-paragraph>
                  </a-typography>

                </div>
              </a-col>
            </a-row>


          </a-card>

        </div>

      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>


export default {
  setup() {

    return {
    }
  },
  name: 'HomeView',
  data(){
    return{
      datalist:[
        {
          name:'地区',
          info:'华东1（杭州）',
          copy:false
        },
        {
          name:'实例ID',
          info:'i-bp1ah7hohzv4jaa31s23',
          copy:false
        },
        {
          name:'CPU/RAM',
          info:'1 核 2 GiB',
          copy:false
        },
        {
          name:'公网带宽',
          info:'2 Mbps',
          copy:false
        },
        {
          name:'公网IP',
          info:'121.40.160.82',
          copy:true
        }
      ]
    }
  },
  components: {

  },
  created() {
    document.title = 'AlibabaCloud Server Entrance'
  }
}
</script>
<style scoped>
.menu-demo {
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  background-color: var(--color-neutral-2);
}
</style>